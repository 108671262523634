import { IGraphQLBackendApi } from "@/services/IGraphQLBackendApi";
import { AxiosInstance } from "axios";
import { GraphQLResponse } from "@/models/GraphQLResponse";
import { inject, injectable } from "inversify";
import TYPES from "@/types";
import { GraphQLError } from "@/errors/GraphQLError";
import { IApiError } from "@/errors/IApiError";
import { MultiApiError } from "@/errors/MultiApiError";
import { DocumentNode, ExecutionResult } from "graphql";
import ApolloClient, { ApolloError } from "apollo-client";
import { restartWebsockets } from "vue-cli-plugin-apollo/graphql-client";
import { UserInfo } from "@/models/UserInfo";
import gql from "graphql-tag";
import version from "@/graphql/version.graphql";
import appName from "@/graphql/appName.graphql";
import { deleteCookie, getCookie } from "@/helpers/CookieExtractor";
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions";
import { TwoFactorAuthQRCode } from "@/models/TwoFactorAuthQRCode";
import { LoginResponse } from "@/models/LoginResponse";

@injectable()
export class GraphQLApi implements IGraphQLBackendApi {
  private _axiosClient: AxiosInstance;
  private _apolloClient: ApolloClient<any>;

  constructor(
    @inject(TYPES.AxiosInstance) axiosClient: AxiosInstance,
    @inject(TYPES.ApolloClient) apolloClient: ApolloClient<any>
  ) {
    this._axiosClient = axiosClient;
    this._apolloClient = apolloClient;
  }

  init(): Promise<any> {
    return this._axiosClient.get("/sanctum/csrf-cookie").then((response) => {
      localStorage.setItem("XSRF-TOKEN", getCookie("XSRF-TOKEN"));
      return true;
    });
  }

  mutation(
    mutation: string,
    variables?: { [index: string]: string }
  ): Promise<GraphQLResponse> {
    return this._axiosClient
      .post<GraphQLResponse>("", { query: mutation, variables })
      .then((response) => {
        if (response.data.errors) {
          const errors: IApiError[] = [];

          for (const error of response.data.errors) {
            errors.push(new GraphQLError(error));
          }
          return Promise.reject(
            errors.length === 1 ? errors[0] : new MultiApiError(errors)
          );
        } else {
          return response.data;
        }
      });
  }

  query(
    query: string,
    variables?: { [index: string]: string }
  ): Promise<GraphQLResponse> {
    return this._axiosClient
      .post<GraphQLResponse>("", { query, variables })
      .then((response) => {
        if (response.data.errors) {
          const errors: IApiError[] = [];

          for (const error of response.data.errors) {
            errors.push(new GraphQLError(error));
          }
          return Promise.reject(
            errors.length === 1 ? errors[0] : new MultiApiError(errors)
          );
        } else {
          return response.data;
        }
      });
  }

  setAccessToken(token: string) {
    if (token) {
      this._axiosClient.defaults.headers.Authorization = `Bearer ${token}`;
      return this.onLogin(this._apolloClient, token);
    } else {
      this.onLogout(this._apolloClient);
      return Promise.resolve(true);
    }
  }

  get accessToken(): string {
    const token = localStorage.getItem("access_token");
    return token ? token : "";
  }

  apolloMutation<T>(
    dataKey: string,
    mutation: DocumentNode,
    variables?: { [index: string]: string },
    refetchQueries?:
      | ((result: ExecutionResult<T>) => RefetchQueryDescription)
      | RefetchQueryDescription
  ): Promise<T> {
    return this._apolloClient
      .mutate<T>({
        mutation,
        variables,
        refetchQueries,
      })
      .then((response: any) => {
        return response.data[dataKey];
      })
      .catch((apolloError: ApolloError) => {
        if (apolloError.graphQLErrors) {
          const errors: IApiError[] = [];

          for (const error of apolloError.graphQLErrors) {
            errors.push(new GraphQLError(error));
          }
          return Promise.reject(
            errors.length === 1 ? errors[0] : new MultiApiError(errors)
          );
        } else {
          return Promise.reject(apolloError);
        }
      });
  }

  apolloQuery<T>(
    dataKey: string,
    query: DocumentNode,
    variables?: { [index: string]: string }
  ): Promise<T> {
    return this._apolloClient
      .query<T>({
        query,
        variables,
        fetchPolicy: "network-only",
      })
      .then((response: any) => {
        return response.data[dataKey];
      })
      .catch((apolloError: ApolloError) => {
        if (apolloError.graphQLErrors) {
          const errors: IApiError[] = [];

          for (const error of apolloError.graphQLErrors) {
            errors.push(new GraphQLError(error));
          }
          return Promise.reject(
            errors.length === 1 ? errors[0] : new MultiApiError(errors)
          );
        } else {
          return Promise.reject(apolloError);
        }
      });
  }

  // Manually call this when user log in
  onLogin(client: any, token: string) {
    let previousToken: string | null = null;
    if (typeof localStorage !== "undefined" && token) {
      previousToken = localStorage.getItem("access_token");
      localStorage.setItem("access_token", token);
    }
    if (client.wsClient) {
      restartWebsockets(client.wsClient);
    }

    return previousToken !== token
      ? client
          .resetStore()
          .then(() => true)
          .catch((e: Error) => {
            // tslint:disable-next-line no-console
            console.log(
              "%cError on cache reset (login)",
              "color: orange;",
              e.message
            );
            return false;
          })
      : Promise.resolve(true);
  }

  reset(): Promise<any> {
    return this.onLogout(this._apolloClient);
  }

  // Manually call this when user log out
  async onLogout(client: any) {
    if (typeof localStorage !== "undefined") {
      localStorage.removeItem("access_token");
    }
    if (client.wsClient) {
      restartWebsockets(client.wsClient);
    }
    try {
      await client.clearStore();
    } catch (e) {
      // tslint:disable-next-line no-console
      console.log(
        "%cError on cache reset (logout)",
        "color: orange;",
        e.message
      );
    }
  }

  login(email: string, password: string): Promise<LoginResponse> {
    return this._axiosClient
      .post("/login", { email, password })
      .then((authPayload) => {
        localStorage.setItem("XSRF-TOKEN", getCookie("XSRF-TOKEN"));
        return authPayload.data;
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 419) {
          window.location.reload();
        }
        return Promise.reject(error);
      });
  }

  logout(): Promise<boolean | void> {
    return this._axiosClient
      .post("/logout")
      .then((response) => {
        return this.onLogout(this._apolloClient);
      })
      .then(() => {
        return this.init();
      });
  }

  impersonate(userId: string) {
    return this._axiosClient.post("/impersonate", { user_id: userId });
  }

  stopImpersonate() {
    return this._axiosClient.delete("/impersonate").then((response) => {
      deleteCookie("impersonate");
      return true;
    });
  }

  confirmLogin(accessToken: string): Promise<UserInfo> {
    return this.apolloQuery<UserInfo>(
      "me",
      gql`
        query {
          me {
            name
            permissions {
              name
            }
            id
            email
            is_teacher
            is_student
            is_admin
            teacher_id
            student_id
            two_factor_auth_enabled
            last_successful_login
            recent_failed_attempts
          }
        }
      `
    ).then((userInfo) => {
      return this.setAccessToken(accessToken).then(() => {
        return userInfo;
      });
    });
  }

  getVersion(): Promise<string> {
    return this.apolloQuery("appVersion", version);
  }

  getName(): Promise<string> {
    return this.apolloQuery("appName", appName);
  }

  confirmPassword(password: string): Promise<boolean> {
    return this._axiosClient
      .post("/user/confirm-password", { password })
      .then((response) => {
        return true;
      });
  }

  confirmTwoFactorAuth(code: string): Promise<string[]> {
    return this._axiosClient
      .post("/user/confirmed-two-factor-authentication", { code })
      .then((response) => {
        return this.getRecoveryCodes();
      });
  }

  disableTwoFactorAuth(): Promise<boolean> {
    return this._axiosClient
      .delete("/user/two-factor-authentication")
      .then((response) => true);
  }

  enableTwoFactorAuth(): Promise<TwoFactorAuthQRCode> {
    return this._axiosClient
      .post("/user/two-factor-authentication")
      .then((response) => {
        return this._axiosClient.get("/user/two-factor-qr-code");
      })
      .then((response) => {
        return response.data;
      });
  }

  twoFactorChallenge(code: string, isRecoveryCode: boolean): Promise<UserInfo> {
    const payload = isRecoveryCode ? { recovery_code: code } : { code };
    return this._axiosClient
      .post("/two-factor-challenge", payload)
      .then(() => {
        return this.init();
      })
      .then((response) => {
        return this.confirmLogin("");
      });
  }

  getRecoveryCodes(): Promise<string[]> {
    return this._axiosClient
      .get("/user/two-factor-recovery-codes")
      .then((response) => {
        return response.data;
      });
  }

  regenerateRecoveryCodes(): Promise<string[]> {
    return this._axiosClient
      .post("/user/two-factor-recovery-codes")
      .then((response) => {
        return this.getRecoveryCodes();
      });
  }
}
